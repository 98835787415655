<template>
  <div>
    <b-modal
      modal
      scrollable
      title="CREATE NCR REALTOR"
      v-model="ownControl"
      size="lg"
      modal-class="modal-primary "
      @hidden="closeModalCreateNcrRealtor"
      title-tag="h3"
      :no-close-on-backdrop="true"
    >
      <ValidationObserver ref="form">
        <!-- Account -->
        <b-row>
          <b-col cols="6">
            <b-input-group class="mr-1">
              <b-form-input
                placeholder="Account"
                v-model="client.account"
                disabled
              />
              <b-input-group-append v-if="buttonSearch">
                <b-button variant="primary"> Search </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <div class="d-flex align-items-center">
              <verify-status-request
                ref="verifyStatusRequest"
                :type-permission-id="3"
                :id-table="client.account"
                @state="wasApprovedCheif = $event"
              ></verify-status-request>
            </div>
          </b-col>
        </b-row>
        <!-- Client -->
        <b-row class="mt-1">
          <b-col cols="6">
            <h5 class="text-left">Client</h5>
            <p
              class="rounded text-primary border-primary font-medium-1 text-center py10"
            >
              {{
                client.client_name != null ? client.client_name : client_name
              }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-1 rounded">
          <b-col cols="9">
            <div class="d-flex justify-content-start">
              <p class="px-3 paddingY bg-primary bordeL text-white fontW my-0">
                AMOUNT
              </p>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                :class="
                  ((option == '' && stateOthers == false) ||
                    (stateOthers && other == '')) &&
                  onSaveAmount
                    ? 'border-danger'
                    : 'border-light'
                "
                class="w-100 m-0"
                required
              >
                <div
                  class="d-flex justify-content-around my-0 border bordeR paddingY"
                >
                  <b-form-radio
                    v-model="option"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="0.00"
                    @change="others(false)"
                    >$ 0.00</b-form-radio
                  >
                  <b-form-radio
                    v-model="option"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="50.00"
                    @change="others(false)"
                    >$ 50.00</b-form-radio
                  >
                  <b-form-radio
                    v-model="option"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="2"
                    @change="others(true)"
                    >Others</b-form-radio
                  >
                </div>
              </b-form-group>
            </div>
          </b-col>

          <b-col v-if="stateOthers" cols="3" class="d-flex justify-content-end">
            <ValidationProvider
              name="other"
              :rules="`required|validate-amount|min_value:` + 50"
              v-slot="{ errors }"
            >
              <b-input-group prepend="$" class="inputOthers">
                <money
                  v-model="other"
                  class="form-control"
                  v-bind="vMoney"
                  :class="{
                    'border border-danger': errors[0] && vmoneyValidate,
                  }"
                />
              </b-input-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- Teble Card -->
        <b-row v-if="showCards && cards" class="mt-1 fontW">
          <b-col>
            <credit-card-table
              :cards="cards"
              :optionName="option"
              @cardId="cardId"
            />
          </b-col>
        </b-row>
        <!-- Observation -->
        <b-row>
          <b-col cols="12">
            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Observation :">
                <b-form-textarea
                  id="message"
                  v-model="observation"
                  rows="2"
                  maxlength="1000"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <div class="d-flex justify-content-center">
          <b-button variant="success" @click="saveNcrRealtor">Send</b-button>
        </div>
      </template>
      <!-- Modal Approve -->
      <modal-approve-supervisor
        v-if="ModalApproveSupervisor"
        :modalApproveSupervisor="ModalApproveSupervisor"
        @closeModalApprove="closeModalApprove"
        @approveSup="approveSup"
      />
      <modal-send-request-permission
        v-if="showModalAppro"
        @close="showModalAppro = false"
        :type-permission-id="3"
        :id-table="client.account"
        @updatePermission="refreshVerifyStatusRequest"
      ></modal-send-request-permission>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop";
// Import Services
import ClientDashboardService from "../../services/clients.dashboard.services";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import CreditCardTable from "@/views/commons/components/ncr-programs/components/table/card/CreditCardTable.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import VMoney from "v-money";
import ModalSendRequestPermission from "@/views/commons/components/permissions/ModalSendRequestPermission.vue";
import VerifyStatusRequest from "@/views/commons/components/permissions/components/VerifyStatusRequest.vue";
import PermissionService from "@/views/commons/components/permissions/services/permission.service.js";

export default {
  components: {
    DragAndDrop,
    CreditCardTable,
    ModalApproveSupervisor,
    ModalSendRequestPermission,
    VerifyStatusRequest,
  },
  props: {
    //
  },
  data() {
    return {
      ownControl: false,
      openModalCreateNcrRealtor: this.modalCreateNcrRealtor,
      option: "",
      buttonSearch: false,
      observation: "",
      files: [],
      client_name: "",
      client_account_id: "",
      other: "",
      requiredNewAppr: this.$route.matched[1].meta.requiredNewAppr
        ? true
        : false,
      showModalAppro: null,
      wasApprovedCheif: {
        status: "NOT",
        exists: false,
        code: null,
      },
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "cardholdername",
          label: "Card Holder Name",
        },
        {
          key: "cardnumber",
          label: "Card Number",
        },
        {
          key: "type_card",
          label: "Type",
        },
        {
          key: "card_expi_month",
          label: "MM",
        },
        {
          key: "card_expi_year",
          label: "YY",
        },
        {
          key: "cardsecuritycode",
          label: "CVC",
        },
      ],
      bindMoney: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        min: 0.0,
      },
      onSaveAmount: false,
      showCards: false,
      ModalApproveSupervisor: false,
      cards: [],
      card: "",
      typencr: this.typencr || "",
      stateOthers: false,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      vmoneyValidate: false,
      dataToApprove: {
        client: this.item.fullname,
        account: this.item.account,
        document: "",
        price: "",
        observation: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.ownControl = true;
    this.searchAccount();
  },
  methods: {
    closeModalCreateNcrRealtor() {
      this.$emit("closeModalCreateNcrRealtor");
    },
    others(value) {
      this.stateOthers = value;
      this.onSaveAmount = false;
    },
    closeModalApprove() {
      this.ModalApproveSupervisor = false;
    },
    cardId(value) {
      this.card = value.id;
      this.typencr = value.type_card;
    },
    searchAccount: async function () {
      try {
        let params = {
          account: this.client.account,
          program: this.client.program_id,
          session: this.currentUser.user_id,
          role: this.currentUser.role_id,
        };
        this.addPreloader();
        const response = await ClientDashboardService.searchAccountNcr(params);
        this.cards = JSON.parse(response.data[0].cards);
        this.showCards = true;
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong Search Account!"
        );
      }
    },
    saveNcrRealtor: async function () {
      this.vmoneyValidate = true;
      this.$refs.form.validate().then(async (success) => {
        if (
          success &&
          !(
            (this.option == "" && this.stateOthers == false) ||
            (this.stateOthers && this.other == "")
          )
        ) {
          if (
            this.moduleId == 7 ||
            this.currentUser.role_id == 1 ||
            this.currentUser.role_id == 2 ||
            [14, 16, 17].some((item) => item == this.currentUser.role_id) ||
            (this.currentUser.role_id == 15 && parseFloat(this.option || 0) > 0)
          ) {
            const confirm = await this.showConfirmSwal();
            if (confirm.isConfirmed) {
              try {
                this.addPreloader();
                const params = {
                  id_account: this.$route.params.idClient,
                  amount: this.option || this.other,
                  obs: this.observation,
                  session: this.currentUser.user_id,
                  card: this.option == 0 ? null : this.card,
                  module: this.moduleId,
                  typencr: 1,
                };
                const data = await ClientDashboardService.insertNcrRealtor(
                  params
                );
                this.removePreloader();
                if (data.status === 200) {
                  if (data.data.code == 1 || data.data.code == 500) {
                    this.showToast(
                      "danger",
                      "top-right",
                      data.data.message.toUpperCase(),
                      "XIcon",
                      "It couldn't be created"
                    );
                  } else {
                    this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
                    this.closeModalCreateNcrRealtor();
                  }
                } else {
                  this.showErrorSwal();
                }
              } catch (error) {
                this.removePreloader();
                this.showToast(
                  "danger",
                  "top-right",
                  "Error",
                  "XIcon",
                  "Something went wrong!"
                );
              }
            }
          } else {
            if (!this.requiredNewAppr) {
              this.ModalApproveSupervisor = true;
            } else {
              if (
                this.wasApprovedCheif.exists &&
                this.wasApprovedCheif.status === "APPROVED"
              ) {
                await this.approveSup();
                await this.updateTimesUsedPermission();
              } else {
                this.showModalAppro = true;
              }
            }
          }
        } else {
          this.onSaveAmount = true;
          return;
        }
      });
    },
    approveSup: async function () {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id_account: this.$route.params.idClient,
            amount: this.option || this.other,
            obs: this.observation,
            session: this.currentUser.user_id,
            card: this.option == 0 ? null : this.card,
            module: this.moduleId,
            typencr: 1,
          };
          this.addPreloader();
          const response = await ClientDashboardService.insertNcrRealtor(
            params
          );
          this.removePreloader();
          if (response.status === 200) {
            if (response.data.code == 1 || response.data.code == 500) {
              this.showToast(
                "danger",
                "top-right",
                response.data.message.toUpperCase(),
                "XIcon",
                "It couldn't be created"
              );
            } else {
              this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
              this.closeModalCreateNcr();
            }
          }
        } catch (error) {
          this.removePreloader();
          console.log(error);
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with Create NCR!"
          );
        }
      }
    },
    approveSup: async function () {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id_account: this.$route.params.idClient,
            amount: this.option || this.other,
            obs: this.observation,
            session: this.currentUser.user_id,
            card: this.option == 0 ? null : this.card,
            module: this.moduleId,
            typencr: 1,
          };
          this.addPreloader();
          const response = await ClientDashboardService.insertNcrRealtor(
            params
          );
          this.removePreloader();
          if (response.status === 200) {
            if (response.data.code == 1 || response.data.code == 500) {
              this.showToast(
                "danger",
                "top-right",
                response.data.message.toUpperCase(),
                "XIcon",
                "It couldn't be created"
              );
            } else {
              this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
              this.closeModalCreateNcrRealtor();
            }
          }
        } catch (error) {
          this.removePreloader();
          console.log(error);
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with Create NCR!"
          );
        }
      }
    },

    async updateTimesUsedPermission() {
      const data = await PermissionService.updateTimesUsedStatusRequest({
        code: this.wasApprovedCheif.code,
      });
    },

    refreshVerifyStatusRequest() {
      this.$refs.verifyStatusRequest.getVerifyStatusRequest();
      this.showModalAppro = false;
    },
  },
};
</script>
